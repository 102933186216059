<template>
  <div style="background: #fff; padding-bottom: 40px; width: 100%">
    <!-- 投标信息 -->
    <el-form ref="form" :model="form" style="margin-bottom: 20px">
      <el-descriptions direction="vertical" :column="3" border style="width: 100%">
        <template slot="title">
          <span>投标信息</span>
        </template>
        <el-descriptions-item>
          <template slot="label"> 标的名称 </template>
          <el-form-item label-width="0px" prop="bidName" ref="bidName" style="width: 100%">
            <el-input
              v-model.trim="form.bidName"
              placeholder="请输入标的名称"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 标的类型 </template>
          <el-form-item prop="bidType">
            <Dictionary
              :disabled="!canWrite"
              :clearable="false"
              v-model="form.bidType"
              code="BID_TYPE"
              placeholder="请选择标的类型"
            />
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 业主单位 </template>
          <el-form-item style="width: 100%" prop="bidOwnerUnit" ref="bidOwnerUnit">
            <el-input
              v-model="form.bidOwnerUnit"
              placeholder="请输入业主单位"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 代理招标单位 </template>
          <el-form-item style="width: 100%" prop="bidAgency" ref="bidAgency">
            <el-input
              v-model="form.bidAgency"
              placeholder="请输入代理招标单位"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 是否重点关注 </template>
          <el-form-item prop="bidHostEscort" ref="bidHostEscort">
            <el-radio-group v-model="form.bidHostEscort" :disabled="!canWrite">
              <el-radio label="IS_FLAG">是</el-radio>
              <el-radio label="NOT_FLAG">否 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 报名截止时间 </template>
          <el-form-item prop="bidRegistrationDeadline" ref="bidRegistrationDeadline">
            <el-date-picker
              v-model="form.bidRegistrationDeadline"
              type="datetime"
              placeholder="选择报名截止时间"
              value-format="timestamp"
              :disabled="!canWrite"
            >
            </el-date-picker>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 开标时间 </template>
          <el-form-item prop="bidOpeningTime" ref="bidOpeningTime">
            <el-date-picker
              v-model="form.bidOpeningTime"
              type="datetime"
              value-format="timestamp"
              placeholder="选择开标时间"
              :disabled="!canWrite"
            >
            </el-date-picker>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 开标地点 </template>
          <el-form-item prop="bidOpeningLocation" style="width: 100%">
            <el-input
              v-model.trim="form.bidOpeningLocation"
              placeholder="请输入开标地点"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 开标方式 </template>
          <el-form-item prop="bidOpeningMethod" style="width: 100%">
            <Dictionary
              :disabled="!canWrite"
              style="width: 100%"
              v-model="form.bidOpeningMethod"
              class="ipt_width"
              code="BID_OPENING_METHOD"
              placeholder="请选择开标方式"
            />
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 标的状态 </template>
          <el-form-item prop="bidStatus" style="width: 100%">
            <el-radio-group v-model="form.bidStatus" :disabled="!canWrite">
              <el-radio label="bidding">投标中</el-radio>
              <el-radio label="winner">已中标</el-radio>
              <el-radio label="loser">未中标</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label"> 中标金额（元） </template>
          <el-form-item prop="bidWinningAmount" style="width: 100%">
            <el-input
              placeholder="请输入中标金额"
              @input="inputFn('bidWinningAmount')"
              @focus="focusFn('bidWinningAmount')"
              @blur="blurFn('bidWinningAmount')"
              v-model="form.bidWinningAmount"
              :disabled="!canWrite"
            >
            </el-input
          ></el-form-item>
        </el-descriptions-item>
      </el-descriptions>
    </el-form>
    <!-- 项目实施前期成本总结 -->
    <el-descriptions
      v-if="form.id"
      title="项目实施前期成本总结"
      direction="vertical"
      :column="4"
      border
    >
      <el-descriptions-item
        label="投标报名费"
        label-class-name="labelClassName"
        contentClassName="contentClassName"
        >{{ form.bidManagementCost.biddingEnrollmentFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="劳务费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ form.bidManagementCost.laborFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="打印装订费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ form.bidManagementCost.printingAndBindingFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="交通费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ form.bidManagementCost.transportationFee | thousands }}</el-descriptions-item
      >

      <el-descriptions-item
        label="中标服务费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ form.bidManagementCost.bidServiceFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="餐费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ form.bidManagementCost.mealFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item
        label="CA介质新办及续期费"
        label-class-name="labelClassName"
        content-class-name="contentClassName"
        >{{ form.bidManagementCost.caMediaNewAndRenewalFee | thousands }}</el-descriptions-item
      >
      <el-descriptions-item label="住宿费">{{
        form.bidManagementCost.accommodationFee | thousands
      }}</el-descriptions-item>
      <el-descriptions-item label="快递费">{{
        form.bidManagementCost.expressage | thousands
      }}</el-descriptions-item>
      <el-descriptions-item label="投标总费用">{{
        form.bidManagementCost.totalBidExpense | thousands
      }}</el-descriptions-item>
      <el-descriptions-item label="其他">{{
        form.bidManagementCost.other | thousands
      }}</el-descriptions-item>
    </el-descriptions>

    <!-- 保证金退回 -->
    <div class="public-title">保证金闭环管理</div>
    <template v-for="(item, index) in bidSecurityDeposits">
      <el-card :key="index" style="margin-top: 5px">
        <div slot="header">
          <div class="header-top">
            {{ item.typeTwo | dict(bidTyleList) }}：<span class="input-money">
              {{ item.sumCost | thousands }}</span
            >
          </div>
        </div>

        <el-table :data="item.SecurityDepositsList" border style="width: 100%">
          <el-table-column type="index" label="序号" align="center" width="70"> </el-table-column>
          <el-table-column prop="costType" align="center" label="单据类型" width="130">
          </el-table-column>
          <el-table-column prop="expenseNumber" align="center" label="单据编号" min-width="180">
          </el-table-column>
          <el-table-column prop="applicantName" align="center" label="申请人" min-width="110">
          </el-table-column>
          <el-table-column
            prop="companyType"
            align="center"
            label="费用所属单位"
            :show-overflow-tooltip="false"
            min-width="110"
          >
            <template slot-scope="scope">
              {{ scope.row.companyType | dict(dictData.companyType) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="taskDate"
            align="center"
            label="申请时间"
            :show-overflow-tooltip="false"
            min-width="110"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            align="center"
            label="保证金金额"
            :show-overflow-tooltip="false"
            min-width="110"
          >
            <template slot-scope="scope">
              {{ scope.row.cost | thousands }}
            </template>
          </el-table-column>
          <el-table-column
            prop="depositReturn"
            align="center"
            label="是否退回"
            :show-overflow-tooltip="false"
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.depositReturn | dict(dictData.bidEarnestMoney) }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="附件"
            :show-overflow-tooltip="false"
            min-width="110"
          >
            <template slot-scope="scope">
              <div class="upload-center">
                <Upload
                  :type="type"
                  v-if="!disabled"
                  :fields="fields"
                  @click.native="uploadIndex(scope.$index, index)"
                  @upload="upload"
                ></Upload>
              </div>

              <div v-for="(n, i) in scope.row.bidManagementFiles" :key="i" style="margin-top: 5px">
                <a
                  v-if="isShowType(n)"
                  style="text-decoration: revert; color: #409eff; padding-right: 8px"
                  target="_blank"
                  :href="`${filepath}${n.filePath}`"
                >
                  {{ n.fileName }}
                </a>
                <span v-else class="img_txt" @click="imgIsShow(n, i)">{{ n.fileName }}</span>

                <i
                  v-if="!disabled"
                  @click="del(i, scope.$index, n, index)"
                  class="iconfont iconerror iconCost"
                ></i>
              </div>
            </template> </el-table-column
        ></el-table>
      </el-card>
    </template>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>
  </div>
</template>

<script>
import { delcommafy } from '@/util/jsencrypt'
export default {
  name: 'BidInfo',
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    ChoicePublicPayment: () => import('@/components/bid/ChoicePublicPayment.vue'),
    ChoiceBusiness: () => import('@/components/bid/ChoiceBusiness.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
  },
  data() {
    return {
      //页面双向绑定用到的数据
      form: {},
      collapseActive: '',
      dictData: {
        bidStatus: [],
        companyType: [],
        bidEarnestMoney: [],
      },

      bidTyleList: [], //投标二级科目字典
      bidSecurityDeposits: [
        {
          typeTwo: null,
          sumCost: null,
          SecurityDepositsList: [
            {
              applicantName: null,
              companyType: null,
              cost: null,
              depositReturn: null,
              expenseNumber: null,
              publicSpendingPaymentApplicationId: null,
              taskDate: null,
              typeTwo: null,
              bidManagementFiles: [],
            },
          ],
        },
      ],
      dialogVisible: false,
      imgList: [],
      temporaryList: [],
      filepath: process.env.VUE_APP_FILEPATH,
      bidType: 'bidSecurity', //保证金附件type
    }
  },
  props: {
    //详情页面传入的数据
    formData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    //当前页面输入框输入状态
    editType: {
      type: String,
      default: '',
    },
  },
  filters: {
    thousands(a) {
      if (!a) {
        return 0
      } else {
        a = Number(a).toLocaleString()
        return a
      }
    },
  },
  created() {
    this.init()
  },
  computed: {
    canWrite() {
      switch (this.editType) {
        case 'add':
          return true
        case 'edit':
          return true
        case 'detail':
          return false
        case 'bidEdit':
          return false
        default:
          return false
      }
    },

    disabled() {
      return this.editType === 'bidEdit' ? false : true
    },
  },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val.id) {
          this.form = val.deepClone()
          this.form.bidWinningAmount = this.form.bidWinningAmount || ''
          this.form.bidManagementCost = this.form.bidManagementCost || {}
          this.blurFn('bidWinningAmount')
          //处理保证金
          this.$api.dict
            .listSysDictData('cun_chu_bao_zheng_jin', true)
            .then(res => {
              this.bidTyleList = []
              this.bidSecurityDeposits = []
              res.data.forEach(v => {
                if (v.dictVal === 'lv_yue_bao_zheng_jin' || v.dictVal === 'tou_bao_zheng_jin') {
                  this.bidTyleList.push(v)
                  this.bidSecurityDeposits.push({
                    typeTwo: v.dictVal,
                    SecurityDepositsList: [],
                  })
                }
              })
              if (val.bidSecurityDeposits) {
                const bidSecurityDeposits = val.bidSecurityDeposits.deepClone()
                // 创建一个映射以便快速访问并更新bidSecurityDeposits中的项目
                const typeTwoMap = new Map()
                this.bidSecurityDeposits.forEach(item => {
                  typeTwoMap.set(item.typeTwo, item)
                  item.SecurityDepositsList = item.SecurityDepositsList || [] // 确保列表已初始化
                })
                // 遍历bidSecurityDeposits，查找和更新匹配的项
                bidSecurityDeposits.forEach(v => {
                  const item = typeTwoMap.get(v.typeTwo)
                  if (item) {
                    // 如果存在匹配的项，则将v添加到SecurityDepositsList并更新sumCost
                    item.SecurityDepositsList.push({
                      bidManagementFiles: [],
                      ...v,
                      depositReturn: String(v.depositReturn),
                      bidManagementFileDeleteList: [],
                    })
                    item.sumCost = (item.sumCost || 0) + v.cost // 确保sumCost已初始化
                  }
                })
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
    },

    options: {
      deep: true,
      immediate: true,
      handler: function (val) {},
    },
  },
  methods: {
    /** 值改变触发 "申请金额" 文本框 **/
    inputFn(e) {
      this.form[e] = String(this.form[e])
        .replace(/[^\d.]/g, '')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$forceUpdate()
    },

    /** 获得焦点触发 "申请金额" 文本框 **/
    focusFn(e) {
      this.form[e] = String(this.form[e]).replace(/,/g, '')
      this.$forceUpdate()
    },

    /** 失去焦点触发 "申请金额" 文本框 **/
    blurFn(e) {
      this.form[e] = Number(this.form[e]).toLocaleString()
      this.$forceUpdate()
    },

    init() {
      this.$api.dict
        .listSysDictData('BID_STATUS', true)
        .then(res => {
          this.dictData.bidStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('BID_EARNEST_MONEY', true)
        .then(res => {
          this.dictData.bidEarnestMoney = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
    imgIsShow(n, index) {
      this.img_index = 0
      this.imgList.forEach((v, i) => {
        if (v.temporaryId) {
          if (v.temporaryId === n.temporaryId) {
            this.img_index = i
          }
        } else if (
          (v.id && n.id && v.id === n.id) ||
          (v.fileName === n.fileName && v.filePath === n.filePath)
        ) {
          this.img_index = i
        }
      })
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
.header-top {
  display: flex;
  align-items: center;
  .input-money {
    display: inline-block;
    width: 200px;
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    border: 1px solid #dcdfe6;
    border-radius: 6px;
  }
}

.public-title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
</style>
